<template>
	<modal
		:visible="show"
		@close="handleClose"
		:title="title"
		centered
		class="confirmModal"
		:okButton="false"
		:cancelButton="false"
	>
		<b-container fluid class="componentContainer">
			<div v-if="content" class="contentHeader">
				<span v-text="this.content"></span>
			</div>
			<div class="confirmForm">
				<div class="confirmFormInputHeader">
					Drivers phone number
				</div>
				<b-form-input
					size="sm"
					title="Phone number"
					type="text"
					ref="phoneNumber"
					@change="onConfirmDriverPhoneChanged($event)"
				>
				</b-form-input>
				<div class="confirmFormInputHeader">
					Drivers email
				</div>
				<b-form-input
					size="sm"
					title="Email"
					type="text"
					@change="onConfirmDriverEmailChanged($event)"
				>
				</b-form-input>
				<div class="confirmFormInputHeader">
					Transport type
				</div>
				<b-form-select
					size="sm"
					title="Type"
					class="modalSelect"
					v-model="confirmOrderType"
					:options="confirmOrderTypes"
					@change="onConfirmOrderTypeChanged($event)"
				>
				</b-form-select>
			</div>
		</b-container>
		<b-container slot="footer" fluid>

			<b-btn slot="modal-cancel" variant="secondary" @click="close" v-text="this.closeButton"></b-btn>
			<b-btn variant="success" @click="send" v-text="this.okButton"></b-btn>
			
		</b-container>
	</modal>
</template>

<script>
import StackableModal from './StackableModal.vue';

export default {
	name: 'ConfirmModal',
	props: ['visible', 'title', 'content', 'okButton', 'closeButton'],
	components: {
		'modal': StackableModal
	},
	data() {
		return {
			show: this.visible,
			shouldSend: null,
			confirmOrderType: 'collectAndDeliver', //Default selected
			confirmInformation: {
				email: null,
				phoneNumber: null,
				orderType: 'collectAndDeliver',
			},
			confirmOrderTypes: [
				{value:'collect', text: 'Collect', disabled: true}, //Disabled untill orderTrips implemented
				{value:'deliver', text: 'Deliver', disabled: true}, //Disabled untill orderTrips implemented
				{value:'collectAndDeliver', text: 'Collect and Deliver'}
			],
		};
	},
	methods: {
		handleClose(){
			this.$emit('close', {shouldSend: this.shouldSend, confirmInformation: this.confirmInformation});
		},
		close() {
			this.shouldSend = false;
			this.show = false;
		},
		send() {
			this.shouldSend = true;
			this.show = false;
		},
		onConfirmDriverPhoneChanged(value){
			if(value === '') value = null;
			if(this.confirmInformation.phoneNumber !== value){
				this.confirmInformation.phoneNumber = value;
			}
		},
		onConfirmDriverEmailChanged(value){
			if(value === '') value = null;
			if(this.confirmInformation.email !== value){
				this.confirmInformation.email = value;
			}
		},
		onConfirmOrderTypeChanged(value){
			if(value === '') value = null;
			if(this.confirmInformation.orderType !== value){
				this.confirmInformation.orderType = value;
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			let self = this;
			self.$refs.phoneNumber.$el.focus();
		});
	}

};
</script>

<style lang="scss" scoped>
.confirmForm .confirmFormInputHeader {
	background-color: inherit;
	text-align: left;
	margin-top: .5em;
	font-weight: 400;
	font-size: 11px;
	color: #9fa8b0;
	line-height: .75rem;
}

.confirmModal /deep/ .modal-header {
	padding:.5rem;
	padding-bottom:.25rem;
	border-bottom: none;
}
.confirmModal /deep/ .modal-header .modal-title{
	font-size:15px;
}
.confirmModal /deep/ .modal-header .close:focus{
	outline: none;
}
.confirmModal /deep/ .modal-footer {
	border-top: none;
	padding: .5rem 0;
}
.confirmModal footer.modal-footer button {
	margin: 0 5px;
}
.confirmModal footer.modal-footer button:first-child {
	margin-left: 0;
}
.confirmModal footer.modal-footer button:last-child {
	margin-right: 0;
}
.modalSelect {
	padding-top: 0;
	padding-bottom: 0;
}

</style>
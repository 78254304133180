<template>
	<!-- <portal to="modals"> -->
	<transition name="fade">
		<div class="timpex-modal-mask" ref="mask" @click="negotiateClose" v-if="showingMask">
			<div class="timpex-modal-wrapper" ref="wrapper">
				<transition name="slide-fade" appear>
					<div class="timpex-modal-container modal-container" v-show="showingContainer">
						<div v-if="!hideHeader" class="timpex-modal-header modal-header" :class="headerClass">
							<h5 class="timpex-modal-title modal-title" v-if="title">{{ title }}</h5>
							<slot name="header" v-else />
							<button v-if="!hideHeaderClose" type="button" aria-label="Close" class="close" @click="close">×</button>
						</div>
						<div class="timpex-modal-body modal-body">
							<slot />
						</div>
						<footer class="timpex-modal-footer modal-footer" :class="footerClass">
							<button v-if="okButton" class="btn btn-primary timpex-modal-actionbutton" @click="onOkClicked">{{ okTitle }}</button>
							<button v-if="cancelButton" class="btn btn-secondary timpex-modal-actionbutton" @click="onCancelClicked">{{ cancelTitle }}</button>
							<slot v-if="!okButton && !cancelButton" name="footer" />
						</footer>
					</div>
				</transition>
			</div>
		</div>
	</transition>
	<!-- </portal> -->
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import newUUID from 'uuid/v4';
import { delay } from '@/core';

export default {
	data() {
		return {
			id: newUUID(),
			showingMask: false,
			showingContainer: false,
			closing: false,
			opening: false,
		};
	},
	props: {
		visible: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		okButton: {
			type: Boolean,
			default: true,
		},
		okTitle: {
			type: String,
			default: 'Ok',
		},
		cancelButton: {
			type: Boolean,
			default: true,
		},
		cancelTitle: {
			type: String,
			default: 'Cancel',
		},
		preventHideOnOk: {
			type: Boolean,
			default: false
		},
		preventHideOnCancel: {
			tryp: Boolean,
			default: false
		},
		hideHeaderClose: {
			type: Boolean,
			default: false
		},
		noCloseOnEsc: {
			type: Boolean,
			default: false
		},
		noCloseOnBackdrop: {
			type: Boolean,
			default: false
		},
		hideHeader: {
			type: Boolean,
			default: false
		},
		footerClass: {
			type: String,
			default: ''
		},
		headerClass: {
			type: String,
			default: ''
		},
	},
	computed: {
		...mapState([ 'modals' ]),
	},
	methods: {
		// PUBLIC FUNCTIONS
		show() {
			this.negotiateAddGlobalKeydownEventListener();
			this.opening = true;
			this.showingMask = true;
			this.showingContainer = true;
			this.$nextTick(() => {
				this.appendModalToStack({ id: this.id, destroy: () => this.close() });
				this.$emit('shown');
				this.opening = false;
			});
		},

		async close() {
			if(this.$listeners && this.$listeners.closing)
				return this.closeWithAbort();
			return this.closeCore();
		},

		// PRIVATE FUNCTIONS
		async closeCore() {
			this.closing = true;
			this.showingContainer = false;
			await delay(150);
			this.showingMask = false;
			await delay(300);
			this.$emit('close');
			this.removeModalFromStack(this.id);
			this.negotiateRemoveGlobalKeydownEventListener();
			this.$nextTick(() => {
				this.closing = false;
			});
		},

		async closeWithAbort() {
			var aborting = await new Promise((resolve) => {
				this.$emit('closing', (shouldAbort) => resolve(shouldAbort === undefined ? true : !!shouldAbort));
			});
			if(aborting)
				return;
			
			this.closeCore();
		},

		negotiateClose(e) {
			if (this.noCloseOnBackdrop) return;
			if(e.target !== this.$refs.mask) return;
			this.close();
		},

		handleEsc(e) {
			if (this.noCloseOnEsc) return;
			switch(e.keyCode) {
			case 27:
				break;
			default:
				return;
			}
			e.preventDefault();
			e.stopPropagation();
			if(this.modals.length >= 1) {
				var modal = this.modals.pop();
				modal.destroy();
			}
		},

		negotiateAddGlobalKeydownEventListener() {
			if(this.modals.length === 0) {
				document.addEventListener('keydown', this.handleEsc);
			}
		},

		negotiateRemoveGlobalKeydownEventListener() {
			if(this.modals.length === 0) {
				document.removeEventListener('keydown', this.handleEsc);
			}
		},

		async onOkClicked() {
			if (!this.preventHideOnOk)
				await this.close();
			this.$emit('ok');
		},

		async onCancelClicked() {
			if (!this.preventHideOnCancel)
				await this.close();
			this.$emit('cancel');
		},

		...mapMutations([
			'appendModalToStack',
			'removeModalFromStack',
		]),
	},
	mounted() {
		this.showingMask = this.visible;
		this.showingContainer = this.visible;
		if(this.visible)
			this.show();
		
	},
	watch: {
		visible(newValue, oldValue) {
			if(this.closing || this.opening || newValue === oldValue) return;
			this.$nextTick(() => {
				if(!newValue)
					this.close();
				else
					this.show();
			});
		},
	}
};
</script>

<style lang="scss" scoped>
.timpex-modal-mask {
	width: 100%;
	min-width: 100vw;
	height: 100%;
	min-height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.6);
	z-index: 2001;
	overflow-y: auto;
	overflow-x: hidden;
	// transform-origin: 50%;
	// transform: scale(2);
}

.timpex-modal-wrapper {
	display: flex;
	min-width: 100vw;
	min-height: 100vh;
    margin: auto;
	padding: 1rem;
	justify-content: center;
	align-items: center;
    pointer-events: none;
}

.timpex-modal-container {
	pointer-events: all;
	max-width: 90vw;
	border-radius: 0.3rem;
	background-clip: padding-box;
	background: #fff;
	border: 1px solid rgba(0,0,0,0.2);
}

.timpex-modal-header {
	padding: 1rem;
}

.timpex-modal-title {
	float: left;
	margin-bottom: 0;
    line-height: 1.5;
}

.timpex-modal-close-button {
	float: right;
	font-size: 1.2rem;
    line-height: 1;
}

.timpex-modal-body {
	position: relative;
	padding: 1rem;
}

.timpex-modal-footer {
	padding: 0.5rem 1rem;
	display: block;

	&::after {
		content: ' ';
		display: block;
		clear: both;
	}
}

.timpex-modal-actionbutton {
	float: right; 
	margin-left: 0.5rem;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
.slide-fade-leave-to {
	transform: translateY(-100px);
	opacity: 0;
}
</style>
